export default function Logo() {
    return (
        <svg width="100%" height="100%" viewBox="0 0 688 240" version="1.1" style={{fillRule:'evenodd',clipRule:'evenodd',strokeLinejoin:'round',strokeMiterlimit:2,}}>
            <rect x="0" y="0" width="687.821" height="239.843" style={{'fill':'none'}} />
            <clipPath id="_clip1">
                <rect x="0" y="0" width="687.821" height="239.843"/>
            </clipPath>
            <g clipPath="url(#_clip1)">
                <g style={{fill:'rgb(52,37,120)', fillRule:'nonzero'}}>
                    <path d="M34.72,239.603L87.888,239.603L103.211,191.006L139.779,191.006L155.102,142.409L118.535,142.409L124.983,121.96L178.391,121.96L194.397,71.198L87.821,71.198L34.72,239.603Z" />
                    <path d="M97.131,0L64.673,57.345L0,71.198L74.681,71.198L97.131,0Z" />
                    <path d="M154.528,239.603L255.089,239.603L271.095,188.841L223.701,188.841L260.796,71.198L207.629,71.198L154.528,239.603Z" />
                    <path d="M268.802,239.603L321.97,239.603L375.071,71.198L321.903,71.198L268.802,239.603Z" />
                    <path d="M442.423,239.843L496.793,239.843L549.97,71.198L497.043,71.198L474.362,143.131L453.575,70.958L399.204,70.958L346.027,239.603L399.195,239.603L421.346,169.354L442.423,239.843Z" />
                    <path d="M547.918,121.96L583.523,121.96L546.428,239.603L599.355,239.603L636.45,121.96L671.815,121.96L687.821,71.198L563.924,71.198L547.918,121.96Z" />
                </g>
            </g>
        </svg>
    )
}