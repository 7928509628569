import * as React from 'react';

/** @public */
export interface UserInfo {
  identity?: string;
  name?: string;
  metadata?: string;
}

/** @public */
export interface UseRoomTokenOptions {
  // userInfo?: UserInfo;
  password: string;
}

/**
 * The `useRoomToken` hook fetches a token from the given token endpoint with the given user info.
 *
 * @example
 * ```tsx
 * const token = useRoomToken(<token-endpoint>, roomName, { userInfo: { identity, name }});
 * ```
 * @public */
export function useRoomToken(
  tokenEndpoint: string | undefined,
  roomName: string,
  options: UseRoomTokenOptions,
) {
  const [roomToken, setRoomToken] = React.useState<string | undefined>(undefined);

  // React.useEffect(() => {
  //   if (options.password === '') {
  //     return
  //   }
  //   if (tokenEndpoint === undefined) {
  //     throw Error('token endpoint needs to be defined');
  //   }
    // if (options.userInfo?.identity === undefined) {
    //   return;
    // }
    const fetchRoomToken = async () => {
      // log.debug('fetching token');
      const params = new URLSearchParams({ ...options, roomName });
      const res = await fetch(`${tokenEndpoint}?${params.toString()}`);
      console.log(res)
      if (!res.ok) {
        // log.error(
        //   `Could not fetch token. Server responded with status ${res.status}: ${res.statusText}`,
        // );
        return;
      }
      const { token } = await res.json();
      console.log('token', token)
      setRoomToken(token);
      return token
    };
    // fetchRoomToken();
  // }, [tokenEndpoint, roomName, JSON.stringify(options)]);
  return {roomToken, fetchRoomToken};
}
